import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {
  get as getAnnouncement
} from 'actions/home/homeAnnouncementAction'
import {
  shoppingCartAddItem
} from 'actions/shoppingCart/shoppingCartAction'
import {
  receive as receiveWeeklyTrend
} from 'actions/home/homeWeeklyTrendAction'
import {
  get,
  receive as receiveProductSignature,
  updateState
} from 'actions/home/homeProductSignatureAction'
// import { updateState } from 'actions/category/categoryAction'
import Router from 'next/router'
import ProductItem from 'container/product/ProductItem'
import color from 'theme/color'
import Image from 'next/image'
import { getUserToken } from 'utils/storage'
// import DeliveryDinein from './DeliveryDineinNew'
// import Search from './Search'
import AsianTrendList from './AsianTrendList'
import TabUser from './TabUser'
// import { FaTicket } from 'react-icons/fa6';

const styles = () => ({
  root: {
    background: color.white,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    padding: '1em',
    background: color.colorRed
  },
  exploreForeground: {
    zIndex: 2,
    background: `linear-gradient(to bottom, ${color.primaryColor} 50%, #ffffff00 50%)`
  },
  scrollY: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
    overflowX: 'overlay',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  showMore: {
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  homeAnnouncement: {
    background: color.colorRed,
    padding: '0 10px',
    display: 'flex'
  },
  homeFirstAnnouncement: {
    background: color.secondaryColor,
    padding: '5px'
  },
  homeFirstBackground: {
    background: color.primaryColor,
    width: '100%',
    padding: '2px 10px'
  },
  divider: {
    color: '#000000',
    marginTop: '10px',
    marginBottom: '10px'
  },
  horizontalScale: {
    scrollSnapType: 'x mandatory',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    minHeight: '180px',
    minWidth: '200px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    whiteSpace: 'nowrap'
  },
  categoryHorizontalScale: {
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'horizontal',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    minHeight: '90px',
    minWidth: '90px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    whiteSpace: 'nowrap'
  },
  categoryHorizontalItem: {
    flex: '0 0 15%',
    width: '90px',
    height: '90px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  productHorizontalScale: {
    display: 'flex',
    flexDirection: 'horizontal',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    minHeight: '90px',
    minWidth: '90px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    whiteSpace: 'nowrap'
  },
  productHorizontalItem: {
    flex: '0 0 33.33%',
    padding: '5px',
    width: '120px',
    alignItems: 'stretch',
    whiteSpace: 'break-spaces'
  },
  imageExplore: {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: 'auto',
    marginRight: '10px',
    maxHeight: '450px',
    borderRadius: '17px',
    scrollSnapAlign: 'center'
  }
})

class Content extends Component {
  state = {
    lastId: 0
  }

  componentDidMount () {
    const {
      dispatch,
      listWeekly,
      listSignature
    } = this.props
    dispatch(receiveWeeklyTrend(listWeekly, {}))
    dispatch(receiveProductSignature(listSignature, {}))
    dispatch(getAnnouncement())
    this.scrollEventListener()
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(updateState({
      list: []
    }))
    window.removeEventListener('scroll', this.handleInfiniteScroll)
  }

  scrollEventListener = () => {
    window.addEventListener('scroll', this.handleInfiniteScroll)
  }

  handleInfiniteScroll = () => {
    const {
      dispatch,
      listProductSignature,
      loadingProductSignature,
      lastPage
    } = this.props
    const endOfPage = window.innerHeight + window.pageYOffset >= document.body.offsetHeight

    if (endOfPage && !loadingProductSignature && !lastPage) {
      window.removeEventListener('scroll', this.handleInfiniteScroll)
      let lastId = 0
      if (listProductSignature && listProductSignature.length > 0) {
        lastId = listProductSignature[listProductSignature.length - 1].signatureId
      }
      // eslint-disable-next-line react/destructuring-assignment
      if (lastId !== this.state.lastId) {
        dispatch(get({ lastId }))
        this.setState({ lastId })
      }
      this.scrollEventListener()
    }
  }

  actionProductItem = () => {
    const {
      dispatch,
      shoppingCartId,
      currentParam
    } = this.props

    return ({
      addQuantity: async (productId, product, stateName) => {
        const token = await getUserToken()
        if (!token) {
          Router.push('/auth/login?source=home')
        }

        if (product.cartQty >= product.stock) {
          return
        }
        const params = {
          transId: shoppingCartId,
          productId,
          qty: product.cartQty + 1,
          memo: null,
          active: 1
        }

        if (currentParam.productId === params.productId && currentParam.qty === params.qty) {
          return
        }

        dispatch(shoppingCartAddItem(params, stateName))
      },
      reduceQuantity: async (productId, product, stateName) => {
        const token = await getUserToken()
        if (!token) {
          Router.push('/auth/login?source=p')
        }
        const params = {
          transId: shoppingCartId,
          productId,
          qty: product.cartQty - 1,
          memo: null,
          active: 1
        }

        if (currentParam.productId === params.productId && currentParam.qty === params.qty) {
          return
        }

        dispatch(shoppingCartAddItem(params, stateName))
      }
    })
  }

  openSearchProduct = (searchValue, searchBy) => {
    if (searchValue.id > 0) {
      let obj = {}
      obj[searchBy] = searchValue.id
      obj.label = searchValue.label
      Router.push({
        pathname: '/search',
        query: obj
      })
    } else {
      Router.push('/category')
    }
  }

  // openCategory = (id) => {
  //   const { dispatch } = this.props
  //   if (id !== 0) {
  //     dispatch(updateCategory({
  //       openedCategory: id
  //     }))
  //   }
  //   Router.push('/category')
  // }

  render () {
    const {
      // loadingWeeklyTrend,
      // loadingExploreStore,
      loadingProductSignature,
      listExploreStore,
      listWeekly,
      listSignature,
      listBuyAgain,
      listBrand,
      listCategory,
      // announcement,
      classes
    } = this.props

    let {
      listWeeklyTrend, listProductSignature
    } = this.props

    if (listWeekly && listWeekly.length > 0 && listWeeklyTrend && listWeeklyTrend.length === 0) {
      listWeeklyTrend = listWeekly
    }

    if (listSignature && listSignature.length > 0 && listProductSignature && listProductSignature.length === 0) {
      listProductSignature = listSignature
    }

    return (
      <div className={classes.root}>

        {/* {announcement && announcement !== '' && (
          <div className={classes.homeAnnouncement}>
            <div className={classes.homeFirstAnnouncement} />
            <div className={classes.homeFirstBackground}>{announcement}</div>
          </div>
        )} */}

        {/* <div className={classes.container}>
          <Search />
        </div> */}

        <div className={classes.exploreForeground} style={{ width: '100%', height: '200px', padding: '0.8em' }}>
          {listExploreStore && listExploreStore.length > 0 ? (
            <div style={{}} className={classes.horizontalScale}>
              {listExploreStore.map((record, index) => (
                <Image
                  key={index}
                  className={classes.imageExplore}
                  loader={({ src }) => {
                    return src
                  }}
                  unoptimized
                  src={record.image}
                  alt={record.image}
                  width={400}
                  height={200}
                />
              ))}
            </div>
          ) : null}
        </div>

        <div>
          <TabUser />
        </div>
        {/* <DeliveryDinein /> */}

        <div style={{ width: '100%', height: '90px' }}>
          {listCategory && listCategory.length > 0 ? (
            <div className={classes.categoryHorizontalScale}>
              {listCategory.map((record, index) => (
                <span
                  className={classes.categoryHorizontalItem}
                  onClick={() => { this.openSearchProduct({ id: record.id, label: record.categoryName }, 'categoryId') }}
                  // onClick={() => { this.openCategory(record.id) }}
                  key={index}
                >
                  <Image
                    key={index}
                    style={{ width: '90px', height: '100%', objectFit: 'contain' }}
                    loader={({ src }) => {
                      return src
                    }}
                    unoptimized
                    src={record.image}
                    alt={record.categoryName}
                    width={90}
                    height={90}
                  />
                </span>
              ))}
            </div>
          ) : null}
        </div>

        <>
          <Divider variant="middle" className={classes.divider} />
          <Box my={1} style={{ width: '98%' }}>
            <Box ml={1} mb={0.5}>
              <Typography color="textPrimary" style={{ fontWeight: 'bold' }}>
                Produk Pilihan
              </Typography>
            </Box>
            {listWeeklyTrend && listWeeklyTrend.length > 0 ? (

              <div className={classes.productHorizontalScale}>
                {listWeeklyTrend.map((record, index) => {
                  return (
                    <span key={index} className={classes.productHorizontalItem}>
                      <ProductItem {...this.actionProductItem()} stateName="weeklytrend" quantity={record.cartQty} item={record} key={index} />
                    </span>
                  )
                })}
              </div>
            ) : null}
          </Box>
        </>

        {
          listBuyAgain && listBuyAgain.length > 0 ? (
            <>
              <Divider variant="middle" className={classes.divider} />
              <Box my={1} style={{ width: '98%' }}>
                <Box ml={1} mb={0.5}>
                  <Typography color="textPrimary" style={{ fontWeight: 'bold' }}>
                    Pesan Kembali
                  </Typography>
                </Box>
                <div className={classes.productHorizontalScale} style={{ height: 'auto', width: '100%' }}>
                  {listBuyAgain.map((record, index) => {
                    return (
                      <span key={index} className={classes.productHorizontalItem}>
                        <ProductItem {...this.actionProductItem()} stateName="buyagain" quantity={record.cartQty} item={record} key={index} />
                      </span>
                    )
                  })}
                </div>
              </Box>
            </>
          ) : null
        }

        <Divider variant="middle" className={classes.divider} />

        <Box my={1} style={{ width: '98%' }}>
          <Box ml={1} mb={0.5}>
            <Typography color="textPrimary" style={{ fontWeight: 'bold' }}>
              Jelajahi Brand
            </Typography>
          </Box>
          {listBrand && listBrand.length > 0 ? (
            <div className={classes.categoryHorizontalScale}>
              {listBrand.map((record, index) => (
                <span
                  className={classes.categoryHorizontalItem}
                  onClick={() => { this.openSearchProduct({ id: record.id, label: record.brandName }, 'brandId') }}
                  key={index}
                >
                  <Image
                    key={index}
                    style={{ width: '90px', height: '100%', objectFit: 'contain' }}
                    loader={({ src }) => {
                      return src
                    }}
                    unoptimized
                    src={record.image}
                    alt={record.brandName}
                    width={90}
                    height={90}
                  />
                </span>
              ))}
            </div>
          ) : null}
        </Box>

        <Divider variant="middle" className={classes.divider} />

        {/* Asian and Trend Food */}
        <AsianTrendList
          list={listProductSignature}
          actionProductItem={() => this.actionProductItem()}
        />
        {loadingProductSignature && <CircularProgress style={{ alignSelf: 'center' }} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  shoppingCartId: state.shoppingCartStore.id,
  lastPage: state.homeProductSignature.lastPage,
  loadingExploreStore: state.homeExploreStore.loading,
  loadingWeeklyTrend: state.homeWeeklyTrend.loading,
  loadingBuyAgain: state.homeBuyAgain.loading,
  loadingBrand: state.homeBrandList.loading,
  loadingCategory: state.homeCategoryList.loading,
  loadingProductSignature: state.homeProductSignature.loading,
  listExploreStore: state.homeExploreStore.list,
  listWeeklyTrend: state.homeWeeklyTrend.list,
  listBuyAgain: state.homeBuyAgain.list,
  listBrand: state.homeBrandList.list,
  listCategory: state.homeCategoryList.list,
  listProductSignature: state.homeProductSignature.list,
  loadingShoppingCart: state.shoppingCartStore.loading,
  currentParam: state.shoppingCartStore.currentParam,
  metaExploreStore: state.homeExploreStore.meta,
  metaWeeklyTrend: state.homeWeeklyTrend.meta,
  metaBuyAgain: state.homeBuyAgain.meta,
  metaBrandList: state.homeBrandList.meta,
  metaCategoryList: state.homeCategoryList.meta,
  announcement: state.homeAnnouncement.announcement,
  metaProductSignature: state.homeProductSignature.meta
})

export default connect(mapStateToProps)(withStyles(styles)(Content))
