import React, { useEffect, useState } from 'react'
import Container from 'container/home/Container'
import CustomHelmet from 'components/CustomHelmet'
import { getWeeklyTrend, getProductSignature } from 'services/home/homeService'
import { getUserToken } from 'utils/storage'
import { encrypt } from 'actions/utils/encryptAction'
import { useRouter } from 'next/router'

function HomePage () {
  const router = useRouter()
  const [listWeekly, setListWeekly] = useState([])
  const [listSignature, setListSignature] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Handle query parameters
    if (router.query?.tableId && router.query?.storeId) {
      const dineincipher = encrypt(JSON.stringify(router.query))
      window.sessionStorage.setItem('DINE_IN', dineincipher)
    }

    // Fetch data
    const fetchData = async () => {
      try {
        const token = getUserToken() // Client-side token retrieval

        // Fetch weekly trends
        const weeklyResponse = await getWeeklyTrend({ page: 1 }, token)
        setListWeekly(weeklyResponse?.success ? weeklyResponse.data : [])

        // Fetch signature products
        const signatureResponse = await getProductSignature({ page: 1, lastId: 0 }, token)
        setListSignature(signatureResponse?.success ? signatureResponse.data : [])
      } catch (error) {
        console.error('Error fetching data:', error)
        setListWeekly([])
        setListSignature([])
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [router.query])

  if (loading) {
    return <div>Loading...</div> // Or your custom loading component
  }

  const containerProps = {
    listWeekly,
    listSignature
  }

  return (
    <div>
      <CustomHelmet
        title="K3Mart"
        description="K3Mart"
      />
      <Container {...containerProps} />
    </div>
  )
}

export default HomePage
