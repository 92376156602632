import React from 'react'
import MyVoucher from './MyVoucher'
import StoresTab from './StoresTab'

const TabUser = () => {
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', placeItems: 'center' }}>
      <div>
        <StoresTab />
      </div>
      <div>
        <MyVoucher />
      </div>
    </div>
  )
}

export default TabUser
