import Link from 'next/link'
import React, { useState } from 'react'

const MyVoucher = () => {
  const [hover, setHover] = useState(false)

  return (
    <div style={{
      display: 'flex', placeContent: 'center'
    }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link href="/user-profile/k3Voucher"
        style={{
          backgroundColor: hover ? '#4DA7A2' : 'white', transition: 'background-color 0.3s ease', borderRadius: '10%', marginTop: '2rem', marginRight: '1rem', marginLeft: '1rem', textDecoration: 'none'
        }}
      >
        <img src="/static/Icon/voucher.svg"
          alt="stores.svg"
          width={80}
          style={
            {
              backgroundColor: hover ? '#4DA7A2' : 'white', transition: 'background-color 0.3s ease', borderRadius: '10%', marginRight: '1rem', marginLeft: '1rem', marginBottom: '-1rem'
            }
          }
        />
        <p style={{
          marginRight: '1rem', marginLeft: '1rem', color: hover ? 'white' : '#4DA7A2', fontWeight: 'bold'
        }}
        >
          K3 Voucher
        </p>
      </Link>
    </div>
  )
}

export default MyVoucher
